import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import Layout from "../../components/layout"
import PageTitle from "../../components/dynamic-components/page-title"
import SectionColumns from "../../components/elements/section-columns"
import { graphql } from "gatsby"
import SEO from "../../components/seo"

const SimplePage = ({ data, location }) => {
  const { title, page_content } = data.sanitySimplePage
  return (
    <Layout>
      <SEO title={title} url={location.pathname} />
      <PageTitle page_title_header={title} />
      <SectionColumns isCentered>
        <div className="column is-8 rich-text">
          {page_content.map(c => (
            <BlockContent key={c._key} blocks={c._rawContent} />
          ))}
        </div>
      </SectionColumns>
    </Layout>
  )
}

export default SimplePage

export const query = graphql`
  query($slug: String!) {
    sanitySimplePage(slug: { current: { eq: $slug } }) {
      title
      page_content {
        _type
        _key
        _rawContent
      }
    }
  }
`
